<template>
  <div class="tips" :class="tipsConfig.size||''">
    <h3 title="点击展开详情" @click="clickbtn" class="title">{{ tipsConfig.title || '备注' }}
    <i :class="this.lineShow?'el-icon-caret-top':'el-icon-caret-bottom'"></i>
    </h3>
    <template v-if="lineShow">
      <div class="line" v-for="(item,i) in tipsList" :key="i+'tips'">
        <a :style="{color: tipsConfig.color}"> {{ i + 1 }}、{{ item.title }}{{ tipsConfig.divide || '：' }}</a><span>{{
          item.text
        }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TipsList',
  props: {
    tipsList: {
      type: Array,
      require: true,
      default() {
        return []
      }
    },
    tipsConfig: {
      // size: 'sm or big'
      type: Object,
      default() {
        return {
          title: '说明',
          divide: '：',
          color: '#ff3176',
          size: 'sm'
        }
      }

    }
  },
  data() {
    return {
      lineShow: false
    }
  },
  methods: {
    clickbtn() {
      this.lineShow = !this.lineShow
    }
  }
}
</script>

<style scoped lang="scss">
.tips {
  //padding: 20px 0;
  text-align: left;
  width: 100%;
  display: inline-block;

  > .line {
    width: 50%;
    float: left;
    padding: 10px 0;
    font-size: 16px;
    color: #909399;
    text-align: left;
    line-height: 10px;

    > a {
      color: #606266;
      font-weight: bold;
    }
  }
}

.tips.sm {
  > h3 {
    font-size: 14px;
  }

  > .line {
    font-size: 12px;

    > a {
      color: #606266;
      font-weight: bold;
    }
  }
}

.tips.big {
  > h3 {
    font-size: 20px;
  }

  > .line {
    font-size: 18px;

    > a {
      color: #606266;
      font-weight: bold;
    }
  }
}

.title {
  cursor: pointer;
}
</style>
