<template>
  <div class="default-table">
    <el-table
        :data='dataList'
        :span-method="objectSpanMethod"
        border>
      <el-table-column
          prop="dept_name"
          label='项目部门'
          align='center'
      >
      </el-table-column>
      <el-table-column
          :label="`${searchCondition.type===2?`第${searchCondition.quarter}季度目标`:`${searchCondition.quarter===1?'上半年':'下半年'}目标`}`"
          prop="target_month"
          align='center'
          :formatter="formatter"
          width='150'>
      </el-table-column>
      <el-table-column
          prop="quotient_1"
          label="营收"
          align="center"
      >
        <el-table-column
            prop="month"
            label="月份"
            align="center"
            min-width="110">
        </el-table-column>
        <el-table-column
            prop="income_month"
            label="月度小计"
            align="center"
            :formatter="formatter"
            min-width="110">
        </el-table-column>
        <el-table-column
            prop="income_total"
            label="季度总计"
            :formatter="formatter"
            align="center"
            min-width="110">
        </el-table-column>
      </el-table-column>
      <el-table-column
          prop="quotient_1"
          label="成本"
          :formatter="formatter"
          align="center"
      >
        <el-table-column
            prop="month"
            label="月份"
            align="center"
            min-width="110">
        </el-table-column>
        <el-table-column
            prop="cost_month"
            label="月度小计"
            :formatter="formatter"
            align="center"
            min-width="110">
        </el-table-column>
        <el-table-column
            prop="cost_total"
            label="季度总计"
            :formatter="formatter"
            align="center"
            min-width="110">
        </el-table-column>
      </el-table-column>
      <el-table-column
          prop="real_profit"
          label="实际利润"
          :formatter="formatter"
          align="center"
          min-width="110">
      </el-table-column>
      <el-table-column
          prop="done_rate"
          label="目标完成率"
          align="center"
          min-width="110">
        <template slot-scope="{row}">
          {{ row.done_rate }} %
        </template>
      </el-table-column>
      <el-table-column
          label='奖金'
          prop="prize"
          align='center'
      >
        <template slot-scope="{row,column}">
          <editable-cell :show-input="false"
                       v-model="row['prize']"
                         v-if="userPermissions.indexOf('pa_kpi_prize__save') !== -1"
                       @change="editVal(row,column.property)" :is-input-number="true"
        >
            <span slot="content">
            {{ valFormat(row[column.property]) }}
              <i class="el-icon-edit default-edit-icon" v-if="showEditIcon"></i>
            </span>
        </editable-cell>
          <span v-else>
              {{ valFormat(row[column.property]) }}
        </span>
        </template>
      </el-table-column>
    </el-table>
  </div>

</template>
<script>
import EditableCell from "@/components/EditableCell.vue";
import {mapGetters} from "vuex";
import {debounce} from "@/utils/debounce";
import {numberFormat} from "@/utils/utils";

export default {
  name: "PerformanceSummaryTable",
  computed: {
    ...mapGetters(['userPermissions'])
  },
  components: {EditableCell},
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  watch: {
    searchCondition: {
      deep: true,
      handler() {
        this.debouncedSearch()
      }
    },
  },
  data() {
    return {
      dataList: [],
      showEditIcon: true,
      mergeFields: ['dept_name', 'target_month', 'income_total', 'cost_total', 'done_rate', 'real_profit', 'prize'],//合并的字段
      debouncedSearch: null
    }
  },
  created() {
    this.debouncedSearch = debounce(this.getList, 500)

  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    objectSpanMethod({row, column, rowIndex}) {
      let len = this.dataList.length;
      const fields = this.mergeFields
      if (fields.indexOf(column.property) > -1) {
        if (rowIndex % len === 0) {
          return {
            rowspan: len,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    async editVal(row, colName) {
      // savePrizeInfoGroup
      let update = {
        ...this.searchCondition,
        month: 1,
        dept_id: this.searchCondition.dpt_id,
        [colName]: row[colName]
      }
      delete update.months
      delete update.group_name
      delete update.dpt_id
      let data = await this.$api.savePrizeInfoGroup(update)
      if (!data) {
        this.$message.error('修改失败')
      } else {
        this.$message.success('保存成功')
        this.getList()
      }
    },
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.dpt_id) {
        condition.dept_id = this.searchCondition.dpt_id
      }
      if (this.searchCondition.group_name) {
        condition.group_name = this.searchCondition.group_name
      }
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      if (this.searchCondition.date_value) {
        condition.date_value = this.searchCondition.date_value
      }
      if (this.searchCondition.quarter) {
        condition.quarter = this.searchCondition.quarter
      }
      if (this.searchCondition.type) {
        condition.type = this.searchCondition.type
      }
      return condition
    },
    async getList() {
      this.isLoading = true
      let searchCondition = this.handleSearchCondition()
      if (this.searchCondition.dpt_id) {
        if (this.searchCondition.type === 2) {
          const {list, page_info} = await this.$api.getPrizeGmList(searchCondition)
          this.dataList = list
          this.pagingData = page_info
          this.isLoading = false
        } else {
          const {list, page_info} = await this.$api.getVpPrizeGmList(searchCondition)
          this.dataList = list
          this.pagingData = page_info
          this.isLoading = false
        }
      }
    },
    formatter(row, value) {
      return (`￥${this.valFormat(Number(row[value.property]))}`)
    },
    valFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
  }
}
</script>


<style scoped>

</style>