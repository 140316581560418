<template>
  <div>
    <el-row class="default-table">
      <el-col :span="24">
        <div v-loading="loadingStatus"
             :element-loading-text="loadingText"
             element-loading-spinner="el-icon-loading">
          <el-table
              :data="dataList"
              :span-method="objectSpanMethod"
              highlight-current-row
              show-summary
              :summary-method="getSummaries"
              style="width: 100%;" border>
            <el-table-column
                prop="nickname"
                label="红人昵称"
                align="center"
                min-width="100">
              <template slot-scope="scope">
                <span :title='`${scope.row.nickname}`'>
                  {{ scope.row.name }}
                  <span style="color: #ff5a91;margin-left: 5px">
                    {{ scope.row.nickname }}</span>
        <i slot="reference" @click="handleSync(scope.row)"
           v-if="userPermissions.indexOf('pa_kpi_prize__save') !== -1"
           style="font-size: 12px;cursor: pointer;color:#FA9841;margin-left: 5px"
           class="el-icon-refresh"></i>
                </span>

              </template>
            </el-table-column>
            <el-table-column
                align="center"
                label="平台"
                min-width="90"
                prop="platform_name">
            </el-table-column>
            <el-table-column
                prop="quotient_1"
                :label="`${searchCondition.type===2?`${rangeData}月`:''}营收`"
                align="center"
            >
              <el-table-column
                  v-for="item in revenueList"
                  :key="item.prop"
                  :prop="item.prop"
                  :label="item.label"
                  :formatter="formatter"
                  align="center"
                  min-width="110">
                <template slot-scope="{row,column}">
                  <editable-cell :show-input="false"
                                 v-model="row[item.prop]"
                                 v-if="userPermissions.indexOf('pa_kpi_prize__save') !== -1"
                                 @change="editVal(row,column.property,'revenue')" :is-input-number="true"
                  >
            <span slot="content">
            {{ valFormat(row[column.property]) }}
              <i class="el-icon-edit default-edit-icon" v-if="showEditIcon"></i>
            </span>
                  </editable-cell>
                  <span v-else>
              {{ valFormat(row[column.property]) }}
        </span>

                </template>
              </el-table-column>
            </el-table-column>
              <el-table-column
                  prop="quotient_1"
                  :label="`${searchCondition.type===2?`${rangeData}月`:''}成本`"
                  align="center"
              >
                <el-table-column
                    v-for="item in costList"
                    :key="item.prop"
                    :prop="item.prop"
                    :label="item.label"
                    :formatter="formatter"
                    align="center"
                    min-width="110">
                  <template slot-scope="{row,column}">
                    <editable-cell :show-input="false"
                                   v-model="row[item.prop]"
                                   @change="editVal(row,column.property,'cost')" :is-input-number="true"
                                   v-if="userPermissions.indexOf('pa_kpi_prize__save') !== -1"

                    >
            <span slot="content">
            {{ valFormat(row[column.property]) }}
              <i class="el-icon-edit default-edit-icon" v-if="showEditIcon"></i>
            </span>
                    </editable-cell>
                    <span v-else>
              {{ valFormat(row[column.property]) }}
            </span>

                  </template>
                </el-table-column>
              </el-table-column>

          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EditableCell from '@/components/EditableCell'
import {numberFormat} from '@/utils/utils'
import {mapGetters} from 'vuex'
import {debounce} from "@/utils/debounce";

const list = [{prop: 'kol_divide_total', label: '网红分成'},
  {prop: 'promotion_total', label: 'MCN推广'},
  {prop: 'live_put_total', label: '直播营销费'},
  {prop: 'expenses_total', label: '费用报销'},
  {prop: 'labor_cost', label: '人工成本'},
  {prop: 'team_prize_fee', label: '团队奖金'},
  {prop: 'biz_supp_team_total', label: '商务支持团队'},
  {prop: 'rebate_total', label: '返点费用'},
  {prop: 'office_rr_total', label: '办公室租金装修费'},
  {prop: 'manage_service_fee', label: '服务管理费'},
  {prop: 'expenses_other', label: '其他（特殊）'},
  {prop: 'expense_total', label: '成本汇总'},]

export default {
  name: 'performanceForm',
  components: {
    EditableCell
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType']),
    loadingText() {
      return `奖金明细数据加载中...`
    },
  },
  props: {
    searchCondition: {
      type: Object,
      default() {
        return {
          id: null
        }
      }
    },
    rangeData: {
      type: [String, Number],
      default() {
        return ''
      }
    },
    infoIds: {
      type: [String, Number],
      default: null,
    }
  },
  watch: {
    searchCondition: {
      deep: true,
      handler() {
        this.debouncedSearch()
      }
    }
  },
  created() {
    this.debouncedSearch = debounce(this.initList, 500)

  },
  data() {
    return {
      loadingStatus: false,
      unsaved: false,
      showEditIcon: true,
      billItems: [],
      dataList: [],
      bonus_bill: {},
      mergeFields: ['nickname', 'group_name', 'quotient_1', 'quotient_2', 'quotient_3', 'total_bonus'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      //start:鼠标右键菜单
      menuData: {
        visible: false,
        top: 0,
        left: 0,
      },
      currentRow: {},
      currentColumn: {},
      editMode: false,
      editGroup: false,
      TableTitle: [],
      debouncedSearch: null,
      spanArrProvince: [],
      spanArrIncome: [],
      spanArrExpense: [],
      revenueList: [
        {prop: 'income_1', label: '商务：采买'},
        {prop: 'income_8', label: '商务：全案'},
        {prop: 'income_3', label: '短视频佣金'},
        {prop: 'income_4', label: '直播佣金'},
        {prop: 'income_5', label: '其他（特殊）'},
        {prop: 'income_total', label: '营收汇总'},
      ],
      costList: list
    }
  },
  methods: {
    // 表格合并行
    calculateSpanArr() {
      this.spanArrProvince = this.calculateSpan(
          (current, prev) => current.nickname === prev.nickname
      );
      this.spanArrIncome = this.calculateSpan(
          (current, prev) =>
              current.nickname === prev.nickname &&
              current.income_total === prev.income_total
      );
      this.spanArrExpense = this.calculateSpan(
          (current, prev) =>
              current.nickname === prev.nickname &&
              current.expense_total === prev.expense_total
      );
    },

    // 通用方法：根据比较函数生成合并信息
    calculateSpan(compareFn) {
      const spanArr = [];
      let pos = 0; // 当前合并组的起始位置
      for (let i = 0; i < this.dataList.length; i++) {
        if (i === 0) {
          spanArr.push(1);
          pos = 0;
        } else {
          const current = this.dataList[i];
          const prev = this.dataList[i - 1];
          if (compareFn(current, prev)) {
            spanArr[pos] += 1;
            spanArr.push(0);
          } else {
            spanArr.push(1);
            pos = i;
          }
        }
      }
      return spanArr;
    },

    // 合并单元格逻辑
    objectSpanMethod({rowIndex, columnIndex}) {
      if (columnIndex === 0) { // nickname
        const rowspan = this.spanArrProvince[rowIndex];
        return {rowspan, colspan: rowspan > 0 ? 1 : 0};
      } else if (columnIndex >= 7) { // 汇总
        const rowspan = this.spanArrIncome[rowIndex];
        return {rowspan, colspan: rowspan > 0 ? 1 : 0};
      } else if (columnIndex === 18) { // 地址列
        const rowspan = this.spanArrExpense[rowIndex];
        return {rowspan, colspan: rowspan > 0 ? 1 : 0};
      }

    },
    valFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    async editVal(row, colName, type) {
      let data = {}
      if (type === 'revenue') {
        data = {
          ids: [row.id],
          [colName]: row[colName],
          kpi_dept_id: this.infoIds
        }
      } else {
        data = {
          ids: [row.id],
          [colName]: row[colName],
          kpi_dept_id: this.infoIds
        }
      }

      let id = await this.$api.editUnionList(data)
      if (!id) {
        this.$message.error('更新失败')
      } else {
        // await this.initList()
        this.$emit('init')
      }
    },
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.dpt_id || this.searchCondition.dept_id) {
        condition.dept_id = this.searchCondition.dpt_id || this.searchCondition.dept_id
      }
      if (this.searchCondition.group_name) {
        condition.group_name = this.searchCondition.group_name
      }
      if (this.searchCondition.year) {
        condition.year = this.searchCondition.year
      }
      if (this.searchCondition.month) {
        let month = this.searchCondition.month
        condition.range_date = `${this.searchCondition.year}-${month > 9 ? month : '0' + month}-01`
      }
      if (this.searchCondition.date_value) {
        condition.date_value = this.searchCondition.date_value
      }
      if (this.searchCondition.quarter) {
        condition.quarter = this.searchCondition.quarter
      }
      if (this.searchCondition.type) {
        condition.type = this.searchCondition.type
      }
      return condition
    },
    async initList() {
      this.loadingStatus = true
      this.billItems = []
      this.bonus_bill = {}
      let searchCondition = this.handleSearchCondition()
      // if (this.searchCondition.type === 1) {
      //   let {list} = await this.$api.getPaKpiRecordList(searchCondition)
      //   this.dataList = list
      // } else {
      //   searchCondition.rangeData = `${this.searchCondition.year}-${this.rangeData <= 9 ? ('0' + this.rangeData) : this.rangeData}-01`
      if (this.searchCondition.type !== 1 && this.rangeData) {
        searchCondition.range_date = `${this.searchCondition.year}-${this.rangeData <= 9 ? ('0' + this.rangeData) : this.rangeData}-01`
      }

        let {list} = await this.$api.getUnionList(searchCondition)
        this.dataList = list
      // }
      this.calculateSpanArr()
      this.loadingStatus = false
    },
    editBonus(row) {
      this.$emit('editBonus', row)
    },
    moneyFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    formatter(row, value) {
      return (`￥${this.valFormat(Number(row[value.property]))}`)
    },
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];


      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = "合计";
          return;
        }

        const values = data.map((item) => Number(item[column.property]));
        const sum = values.reduce((prev, curr) => prev + curr, 0);
        if (sum !== null && !(["nickname", "platform_name", "income_total", "expense_total"].includes(column.property))) sums[index] = numberFormat(sum, 2, '.', ',', 'round')
        let sumCol = ['income_total', 'expense_total','kol_divide_total','promotion_total','live_put_total','expenses_total'
        ,'labor_cost','team_prize_fee','biz_supp_team_total','rebate_total','office_rr_total','manage_service_fee','expenses_other',''
        ];
        // let sums = []; // 假设这是最终合计结果数组
        let total = {}; // 改用对象存储每个字段的累加值
        let prevNicknames = {}; // 为每个字段单独记录前一个 artist_id

// 初始化：为每个需要统计的字段创建累加器和记录
        sumCol.forEach(col => {
          total[col] = 0;
          prevNicknames[col] = null;
        });

        data.forEach((item) => {
          const currentArtistId = item['artist_id'];

          sumCol.forEach(col => {
            // 如果当前遍历的字段是目标列（根据 column.property 判断）
            if (col === column.property) { // 根据实际需求调整判断逻辑
              // 仅当 artist_id 变化时才累加
              if (prevNicknames[col] !== currentArtistId) {
                total[col] += Number(item[col]) || 0;
                prevNicknames[col] = currentArtistId; // 更新记录的 artist_id
              }

              // 格式化结果到 sums 数组（假设 index 是外部变量）
              sums[index] = numberFormat(total[col], 2, '.', ',', 'round');
            // console.log(total[col])
            }
          });
        });


        // console.log(column, index, values)

      });
      return sums;
    }
    ,
    calcCol() {
      if (this.searchCondition.type === 1) {
        let data = [...list]
        let index = data.findIndex((item) => item.prop === 'team_prize_fee')
        data.splice(index, 1)
        this.costList = [...data]
      } else {
        this.costList = [...list]

      }
    },
     handleSync(row) {
      this.$confirm(`确认同步【${row.nickname}】所有数据`, '警告', {
        confirmButtonText: '确定同步',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(async () => {
        let data = {
          type: this.searchCondition.type,
          time_month: `${this.searchCondition.year}-${this.searchCondition.month <= 9 ? ('0' + this.searchCondition.month) : this.searchCondition.month}-01`,
          dept_id: this.searchCondition.dpt_id,
          artist_id: row.artist_id,
          quarter: this.searchCondition.quarter,
          year: this.searchCondition.year,
        }
        if (this.searchCondition.type !== 1 && this.rangeData) {
          data.time_month = `${this.searchCondition.year}-${this.rangeData <= 9 ? ('0' + this.rangeData) : this.rangeData}-01`
        }
        let id = await this.$api.syncUnionList(data)
        if (id) {
          this.$message.success('同步成功')
          await this.initList()
        }

      }).catch(() => {
        this.msgWarn('取消操作')
      })

    }
  },
  mounted() {
    this.calcCol()

  }

}
</script>

<style lang='scss'>
@import "@/pages/lb/style/main.scss";

.goal_tracking_director_main-inner {
  /* 修改合计行背景色和文字颜色 */

  .el-table__footer-wrapper .el-table__footer .el-table__cell {
    background-color: #f5f7fa !important;
    color: #333 !important;
  }

  /* 调整文字对齐方式（默认左对齐） */

  .el-table__footer .el-table__cell {
    text-align: right !important;
  }

  .el-table__footer .el-table__cell .cell {
    font-weight: bold !important;

  }

  /* 修改合计行边框 */
  .el-table__footer-wrapper tr {
    border-top: 2px solid #ebeef5;
  }

}
</style>
