<template>
  <div>
    <!--    筛选条件-->
    <!--    <bonus-search @doSearch="doSearch"/>-->
    <div class="performance-objectives">
      <el-row class="table-search">
        <el-form :model="searchCondition" :inline="true" size="small">
          <el-form-item>
            <el-date-picker
                v-model="searchCondition.year"
                type="year"
                @change="changeOption"
                format="yyyy"
                value-format="yyyy"
                placeholder="选择年度" style="width: 140px">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-popover
                placement="right"
                width="500"
                trigger="click"
            >
              <el-button size="small" type="default" slot="reference">
                时间区间<span v-if="searchCondition.quarter">：{{
                  searchCondition.quarter === 1 ? '上半年' : searchCondition.quarter === 2 ? '下半年' : '未选择'
                }}</span>
              </el-button>
              <el-row>
                <el-col :span="24">
                  <el-radio-group v-model="searchCondition.quarter" @change="changeQuarter">
                    <el-radio-button v-for="(quarter) in quarters" :label="quarter.value" :key="quarter.label">
                      {{ quarter.label }}
                    </el-radio-button>
                  </el-radio-group>
                </el-col>
              </el-row>
            </el-popover>
          </el-form-item>
          <el-form-item>
            <DeptSelectTree :searchCondition='searchCondition' ref='deptTree' style='width: 360px'
                            :isType="'prize'" :isCheckStrictly="false"
                            @handleSelect='handleChangeDept'></DeptSelectTree>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="changeOption" icon='el-icon-search'>查询</el-button>
          </el-form-item>
          <el-form-item>
            <LogPanel :infoIds="kpi_dept_id" buttonSize="small" ref='refLogPanel'
                      v-if="kpi_dept_id!==null" module-name='pa_kpi'></LogPanel>
          </el-form-item>
        </el-form>
      </el-row>
    </div>
    <el-row>
      <performance-summary-table :searchCondition="searchCondition"></performance-summary-table>
      <el-row style="margin-top: 10px">
        <el-button v-has-authority="'pa_kpi_prize__release'" size="medium" style="float: right" type="primary"
                   @click="handleSubmit">发布奖金
        </el-button>
      </el-row>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-radio-group size="medium" style="margin-bottom: 10px" v-model="range_date" @change="changeRange">
        <el-radio-button v-for="i in searchCondition.months" :key="i" :label="i">{{ i }}月</el-radio-button>
      </el-radio-group>
      <!--    列表-->
      <performance-form :rangeData.sync="range_date" ref="performanceForm"
                        @init="changeOption"      :infoIds="kpi_dept_id" :searchCondition="searchCondition"></performance-form>
    </el-row>
    <el-row :gutter="20" style="margin-top: 20px">
      <el-col>
        <span class="title">
          <i class="el-icon-info" style="font-size: 14px;color: #ff3176"></i>
          备注
        </span>
      </el-col>
      <el-col :span="6" style="margin-top: 10px">
        <table class="table">
          <thead>
          <tr>
            <th>目标达成率</th>
            <th>奖金</th>
            <th>额度</th>
          </tr>
          </thead>
          <tbody>
          <tr style="border: 1px solid red">
            <td>85%-95%</td>
            <td>1个月</td>
            <td>{{ valFormat(75000) }}</td>
          </tr>
          <tr>
            <td>95%-105%</td>
            <td>2个月</td>
            <td>{{ valFormat(150000) }}</td>
          </tr>
          <tr>
            <td>105%-120%</td>
            <td>3个月</td>
            <td>{{ valFormat(225000) }}</td>
          </tr>
          <tr>
            <td>120%-135%</td>
            <td>4个月</td>
            <td>{{ valFormat(300000) }}</td>
          </tr>
          <tr>
            <td>135%-150%</td>
            <td>5个月</td>
            <td>{{ valFormat(375000) }}</td>
          </tr>
          <tr>
            <td>150%+</td>
            <td>6个月</td>
            <td>{{ valFormat(450000) }}</td>
          </tr>
          </tbody>
        </table>
      </el-col>
      <el-col>
        <tip-list :tipsList="tipsList"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import BonusEdit from "@/pages/performance/bonus/BonusEdit";
import BonusList from "@/pages/performance/bonus/BonusList";
import {mapGetters} from "vuex";
import PaKolSetting from "@/pages/mcn_performance_data/component/PaKolSetting.vue";
import PaDeptInit from "@/pages/mcn_performance_data/component/PaDeptInit.vue";
// import BonusItemsTable from './component/BonusItemsTable.vue'、
import performanceForm from './component/performanceForm.vue'
import PerformanceSummaryTable from "@/pages/goal_tracking/component/PerformanceSummaryTable.vue";
import DeptSelectTree from "@/pages/mcn_performance_data/component/DeptSelectTree.vue";
import {numberFormat} from "@/utils/utils";
import TipList from "@/components/TipsList.vue";
import LogPanel from "@/components/logs/LogPanel.vue";
import BonusTable from "@/pages/goal_tracking/component/performanceForm.vue";

export default {
  name: "BonusIndex",
  components: {
    BonusTable,
    LogPanel,
    TipList,
    DeptSelectTree,
    PerformanceSummaryTable,
    PaDeptInit,
    PaKolSetting,
    BonusList,
    BonusEdit,
    performanceForm
    // BonusSearch, BonusTable, BonusHandle
  },
  data() {
    return {
      searchCondition: {
        year: null,
        dpt_id: null,
        group_name: '',
        type: 3,
        date_value: 1,
        quarter: [],
        months: []
      },
      dialogVisible: false,
      quarters: [
        {label: '上半年', value: 1},
        {label: '下半年', value: 2},
      ],
      range_date: null,
      tipsList: [
        {title: '商务：采买', text: '营收数据的【商务营收】'},
        {title: '商务：全案', text: '营收数据的【保量收入】'},
        {title: '短视频佣金', text: '营收数据的【短视频佣金】'},
        {title: '直播佣金', text: '营收数据的【直播佣金】'},
        {title: '其他（特殊）', text: '营收数据的【直播坑位费】'},
        {title: '网红分成', text: '红人模型数据的【红人模型-网红分成】'},
        {title: 'MCN推广', text: '红人模型数据的【MCN推广】'},
        {title: '直播营销费', text: '红人模型数据的【直播投放金】'},
        {title: '费用报销', text: '红人模型数据的【报销明细】'},
        {title: '人工成本', text: '红人模型数据的【人工成本】'},
        {title: '商务支持团队', text: '红人模型数据的【商务支持团队费用】'},
        {title: '返点费用', text: '红人模型数据的【返点费用】'},
        {title: '办公租金装修费', text: '红人模型数据的【办公租金装修】'},
        {title: '管理服务费', text: '红人模型数据的【管理服务费】'},
        {title: '其他（特殊）', text: '暂无数据'},
      ],
      kpi_dept_id:null

    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  methods: {
    changeQuarter() {
      //计算季度对应的月份
      let quarter = this.searchCondition.quarter
      this.searchCondition.months = quarter === 1 ? [1, 2, 3, 4, 5, 6] : [7, 8, 9, 10, 11, 12];//月份的集合
      this.searchCondition.date_value = quarter //月份的集合
      this.range_date = quarter === 1 ? 1 : 7//月份的集合
      this.changeOption()

    },
    changeRange() {
      this.$refs.performanceForm.initList()
    },
    handleChangeDept(obj) {
      this.searchCondition.dpt_id = obj.dept_id || null
      this.searchCondition.dpt_name = obj.dept_name || ''
      this.kpi_dept_id = obj.kpi_dept_id

    },
    calcPermission() {
      return this.userPermissions.indexOf('bonus_save') > -1 || this.userPermissions.indexOf('bonus_check_1') > -1 || this.userPermissions.indexOf('bonus_check_2') > -1
    },
    changeOption() {
      this.searchCondition.quarter = this.searchCondition.date_value
      this.$refs['deptTree'].show(this.searchCondition)
    },
    async handleSubmit() {
      let data = {
        year: this.searchCondition.year,
        date_value: this.searchCondition.date_value,
        dept_id: this.searchCondition.dpt_id,
        type: this.searchCondition.type,
        month: 1
      }
      this.$confirm(
          `确定发布【${this.searchCondition.dpt_name},${this.searchCondition.year}${this.searchCondition.date_value === 1 ? '上半年' : '下半年'}】奖金吗吗？`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
        // // releaseTargetData
        let id = await this.$api.releasePrizeInfoGroup(data)
        if (!id) {
          this.$message.error('发布失败')
        } else {
          this.$message.success('发布成功')
        }
      })
    },
    valFormat(value) {
      return numberFormat(value, 0, '.', ',', 'round')
    },

  },
  mounted() {
    this.searchCondition.year = (new Date()).getFullYear() + ''
    // 获取当前季度：
    this.searchCondition.quarter = this.$utils.getCurrentQuarter()
    this.changeQuarter()
    this.changeOption()
  },
}
</script>

<style lang="scss" scoped>
.title {
  margin-top: 5px;
  margin-left: 10px;
  font-size: 12px;
}

.tips {
  margin-top: 10px;
}

//table, th, td {
//  border: 1px solid black;
//  border-collapse: collapse; /* 移除单元格间的间隔 */
//}

table {
  border: 1px solid black;
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  text-align: center;
  border: 1px solid #ddd;
}

/* 隔列换背景色 */
tr:nth-child(even) td:nth-child(odd),
tr:nth-child(odd) td:nth-child(even) {
  background-color: #f2f2f2;
}
</style>